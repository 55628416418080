/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import axios from "axios/axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

const AddEditBlog = (props) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [editing, setEditing] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = () => {
    if (props.selectedBlog) {
      handleEdit();
    } else {
      handleAdd();
    }
  };

  const handleAdd = () => {
    setLoadingSubmit(true);
    setErrorMsg("");
    var fdata = new FormData();
    fdata.append("file", props.blog.img);
    fdata.append("titleEnglish", props.blog.titleEnglish);
    fdata.append("titleArabic", props.blog.titleArabic);
    fdata.append("descriptionEnglish", props.blog.descriptionEnglish);
    fdata.append("descriptionArabic", props.blog.descriptionArabic);
    fdata.append("bodyEnglish", props.blog.bodyEnglish);
    fdata.append("bodyArabic", props.blog.bodyArabic);
    axios
      .post(`blogs`, fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((_) => window.location.reload())
      .catch((e) => setErrorMsg(e.response?.data?.message))
      .finally((_) => {
        setLoadingSubmit(false);
        setTimeout((_) => setErrorMsg(""), 3000);
      });
  };

  const handleEdit = () => {
    setLoadingSubmit(true);
    setErrorMsg("");
    var fdata = new FormData();
    fdata.append("file", props.blog.img);
    fdata.append("titleEnglish", props.blog.titleEnglish);
    fdata.append("titleArabic", props.blog.titleArabic);
    fdata.append("descriptionEnglish", props.blog.descriptionEnglish);
    fdata.append("descriptionArabic", props.blog.descriptionArabic);
    fdata.append("bodyEnglish", props.blog.bodyEnglish);
    fdata.append("bodyArabic", props.blog.bodyArabic);
    axios
      .patch(`blogs/${props.blog._id}`, fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((_) => window.location.reload())
      .catch((e) => setErrorMsg(e.response?.data?.message))
      .finally((_) => {
        setLoadingSubmit(false);
        setTimeout((_) => setErrorMsg(""), 3000);
      });
  };

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this blog, do you wish to continue?"
    );
    if (confirm) {
      axios
        .delete(`blogs/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          props.setBlog({
            titleEnglish: "",
            descriptionEnglish: "",
            titleArabic: "",
            descriptionArabic: "",
            img: null,
          });
          props.setSelectedBlog(null);
          props.setReload((prev) => !prev);
        })
        .catch((e) => {});
    }
  };

  return (
    <>
      <Form
        className="row g-3"
        style={{ width: "100%", marginTop: "1vh" }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          // validation.handleSubmit()
        }}
      >
        <Row style={{ width: "100%", margin: 0 }}>
          {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
          <Col md={6} xs={12}>
            <Row>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Title
                </Label>
                {editing || !props.selectedBlog ? (
                  <Input
                    id="titleEnglish"
                    className="form-control"
                    type="text"
                    placeholder="Title"
                    value={props.blog.titleEnglish}
                    onChange={(e) => {
                      props.setBlog({
                        ...props.blog,
                        titleEnglish: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <p style={{ color: "#5B626B" }}>
                    {props.selectedBlog.titleEnglish
                      ? props.selectedBlog.titleEnglish
                      : ""}
                  </p>
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Description
                </Label>
                {editing || !props.selectedBlog ? (
                  <Input
                    id="descriptionEnglish"
                    className="form-control"
                    type="textarea"
                    rows="2"
                    placeholder="Description"
                    value={props.blog.descriptionEnglish}
                    onChange={(e) => {
                      props.setBlog({
                        ...props.blog,
                        descriptionEnglish: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <p style={{ color: "#5B626B" }}>
                    {props.selectedBlog.descriptionEnglish
                      ? props.selectedBlog.descriptionEnglish
                      : ""}
                  </p>
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Content
                </Label>
                {editing || !props.selectedBlog ? (
                  <Input
                    id="bodyEnglish"
                    className="form-control"
                    type="textarea"
                    rows="2"
                    placeholder="Description"
                    value={props.blog.bodyEnglish}
                    onChange={(e) => {
                      props.setBlog({
                        ...props.blog,
                        bodyEnglish: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <p style={{ color: "#5B626B" }}>
                    {props.selectedBlog.bodyEnglish
                      ? props.selectedBlog.bodyEnglish
                      : ""}
                  </p>
                )}
              </FormGroup>
            </Row>
          </Col>
          <Col md={6} xs={12}>
            <Row>
              <FormGroup className="mt-3 mb-3" dir="rtl">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  العنوان
                </Label>
                {editing || !props.selectedBlog ? (
                  <Input
                    id="titleArabic"
                    className="form-control"
                    type="text"
                    placeholder="العنوان"
                    value={props.blog.titleArabic}
                    onChange={(e) => {
                      props.setBlog({
                        ...props.blog,
                        titleArabic: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <p style={{ color: "#5B626B" }}>
                    {props.selectedBlog.titleArabic
                      ? props.selectedBlog.titleArabic
                      : ""}
                  </p>
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="mt-3 mb-3" dir="rtl">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  الوصف
                </Label>
                {editing || !props.selectedBlog ? (
                  <Input
                    id="descriptionArabic"
                    className="form-control"
                    type="textarea"
                    rows="2"
                    placeholder="الوصف"
                    value={props.blog.descriptionArabic}
                    onChange={(e) => {
                      props.setBlog({
                        ...props.blog,
                        descriptionArabic: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <p style={{ color: "#5B626B" }}>
                    {props.selectedBlog.descriptionArabic
                      ? props.selectedBlog.descriptionArabic
                      : ""}
                  </p>
                )}
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="mt-3 mb-3" dir="rtl">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  المحتوى
                </Label>
                {editing || !props.selectedBlog ? (
                  <Input
                    id="bodyArabic"
                    className="form-control"
                    type="textarea"
                    rows="2"
                    placeholder="الوصف"
                    value={props.blog.bodyArabic}
                    onChange={(e) => {
                      props.setBlog({
                        ...props.blog,
                        bodyArabic: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <p style={{ color: "#5B626B" }}>
                    {props.selectedBlog.bodyArabic
                      ? props.selectedBlog.bodyArabic
                      : ""}
                  </p>
                )}
              </FormGroup>
            </Row>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col md={6} xs={12}>
            <FormGroup className="mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Image
              </Label>
              {editing || !props.selectedBlog ? (
                <Input
                  id="img"
                  className="form-control"
                  type="file"
                  accept="image/*"
                  placeholder="Image"
                  onChange={(e) => {
                    props.setBlog({ ...props.blog, img: e.target.files[0] });
                  }}
                />
              ) : (
                <div>
                  <img
                    src={props.selectedBlog.img}
                    alt=""
                    style={{ maxWidth: "20vw", maxHeight: "20vw" }}
                  ></img>
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>

        {editing || !props.selectedBlog ? (
          <div className="col-12">
            {editing && (
              <button
                className="btn btn-secondary"
                style={{ marginRight: "1vw" }}
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(false);
                }}
              >
                <i className="ion ion-md-close"></i>
              </button>
            )}
            <button
              className="btn btn-primary"
              type={loadingSubmit ? null : "submit"}
            >
              {loadingSubmit ? (
                <Spinner size={"sm"} color="secondary" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        ) : (
          <div className="col-12">
            <button
              className="btn btn-primary"
              style={{ marginRight: "1vw" }}
              onClick={(e) => {
                e.preventDefault();
                setEditing(true);
              }}
            >
              <i className="fas fa-edit"></i>
            </button>

            <button
              className="btn btn-danger"
              onClick={(e) => {
                e.preventDefault();
                handleDelete(props.selectedBlog._id);
              }}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        )}
      </Form>
    </>
  );
};
AddEditBlog.propTypes = {
  blog: PropTypes.any,
  selectedBlog: PropTypes.any,
  setBlog: PropTypes.any,
  setSelectedBlog: PropTypes.any,
  setReload: PropTypes.any,
};

export default AddEditBlog;
