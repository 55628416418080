/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Alert,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Switch from "react-switch"
import axios from "axios/axios"
import Select from "react-select"

const AddEditSubCategory = (props) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: props.subCategory,
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter the name"),
      nameAr: Yup.string().required("Please enter the arabic name"),
    }),
    onSubmit: (values) => {
      handleSubmit(values)
    },
  })

  const handleSubmit = (values) => {
    if (props.selectedSubCategory) {
      handleEdit(values)
    } else {
      handleAdd(values)
    }
  }

  const handleEdit = (values) => {
    setLoadingSubmit(true)
    props.setError(null)
    if (!props.subCategory.category || props.subCategory.category == "") {
      props.setError("Please select a category")
      return
    }
    axios
      .put(
        `products/updateSubCategory/${props.selectedSubCategory._id}`,
        {
          name: values.name,
          nameAr: values.nameAr,
          category: props.subCategory.category,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        props.setReload((prev) => !prev)
        props.setSubCategory({
          name: "",
          nameAr: "",
          category: null,
        })
        props.setSelectedSubCategory(null)
      })
      .catch((e) => {
        props.setError(e.response.data?.message)
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }
  const handleAdd = (values) => {
    setLoadingSubmit(true)
    props.setError(null)

    axios
      .post(
        "products/addSubCategory",
        {
          name: values.name,
          nameAr: values.nameAr,
          category: props.subCategory.category,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        props.setReload((prev) => !prev)
        props.setSubCategory({
          name: "",
          nameAr: "",
          category: null,
        })
      })
      .catch((e) => {
        props.setError(e.response.data?.message)
      })
      .finally(() => {
        setLoadingSubmit(false)
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          validation.handleSubmit()
        }}
      >
        {props.error && <Alert color="danger">{props.error}</Alert>}
        <Row style={{ width: "100%" }}>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
              <Input
                required
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={props.subCategory.name}
                invalid={validation.touched.name && validation.errors.name}
                onChange={(e) => {
                  props.setSubCategory({
                    ...props.subCategory,
                    name: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.name && validation.errors.name}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                {"Name (Ar)"}
              </Label>
              <Input
                required
                id="nameAr"
                className="form-control"
                type="text"
                placeholder="Name (Ar)"
                value={props.subCategory.nameAr}
                invalid={validation.touched.nameAr && validation.errors.nameAr}
                onChange={(e) => {
                  props.setSubCategory({
                    ...props.subCategory,
                    nameAr: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.nameAr && validation.errors.nameAr}
            </FormGroup>
          </Col>
          {/* <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name (fr)
              </Label>
              <Input
                required
                id="nameFr"
                className="form-control"
                type="text"
                placeholder="Name (fr)"
                value={props.subCategory.nameFr}
                invalid={validation.touched.nameFr && validation.errors.nameFr}
                onChange={(e) => {
                  props.setSubCategory({
                    ...props.subCategory,
                    nameFr: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.nameFr && validation.errors.nameFr}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name (it)
              </Label>
              <Input
                required
                id="nameIt"
                className="form-control"
                type="text"
                placeholder="Name (it)"
                value={props.subCategory.nameIt}
                invalid={validation.touched.nameIt && validation.errors.nameIt}
                onChange={(e) => {
                  props.setSubCategory({
                    ...props.subCategory,
                    nameIt: e.target.value,
                  })
                  validation.handleChange(e)
                }}
              />
              {validation.touched.nameIt && validation.errors.nameIt}
            </FormGroup>
          </Col> */}
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Category
              </Label>
              <Select
                required
                id="category"
                classNamePrefix="select2-selection"
                type="text"
                placeholder="Category"
                value={
                  props.categories.find(
                    (one) =>
                      props.subCategory.category &&
                      one.value == props.subCategory.category
                  ) || null
                }
                options={props.categories}
                onChange={(value) => {
                  props.setSubCategory({
                    ...props.subCategory,
                    category: value.value,
                  })
                }}
              />
              {validation.touched.nameIt && validation.errors.nameIt}
            </FormGroup>
          </Col>
        </Row>

        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditSubCategory.propTypes = {
  categories: PropTypes.any,
  selectedSubCategory: PropTypes.any,
  setSelectedSubCategory: PropTypes.any,
  subCategory: PropTypes.any,
  setSubCategory: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditSubCategory
