/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios/axios";
// import { SelectionState } from "draft-js"
// import { QRCodeCanvas } from "qrcode.react"

const AddEditCallCenter = (props) => {
  const [image, setimage] = useState("");

  // const downloadQRCode = (e) => {
  //   // e.preventDefault()
  //   let canvas = qrRef.current.querySelector("canvas")
  //   let image = canvas.toDataURL("image/png")
  //   let anchor = document.createElement("a")
  //   anchor.href = image
  //   anchor.download = `qr-code.png`
  //   document.body.appendChild(anchor)
  //   anchor.click()
  //   document.body.removeChild(anchor)
  //   setimage("")
  // }

  const qrCodeEncoder = (e) => {
    setimage(e.target.files[0]);
  };
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  // const [loadingSubmit1, setLoadingSubmit1] = useState(false)

  // const exportCSV = () => {
  //   setLoadingSubmit1(true)
  //   axios({
  //     url: "qrCode/downloadQrCodes", // Replace with your backend URL
  //     method: "GET",
  //     responseType: "blob", // Specify the response type as blob
  //     headers: {
  //       Authorization: sessionStorage.getItem("token"),
  //     },
  //   })
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]))
  //       const link = document.createElement("a")
  //       link.href = url
  //       link.setAttribute("download", "Codes.zip")
  //       document.body.appendChild(link)
  //       link.click()
  //       document.body.removeChild(link)
  //       setLoadingSubmit1(false)
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading the Excel file:", error)
  //     })
  // }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: props.callCenter,
    validationSchema: Yup.object(
      !props.selectedCallCenter
        ? {
            name: Yup.string().required("Please Enter The Name"),
            email: Yup.string().required("Please Enter The Email"),
            phone: Yup.string()
              .min(11, "The phone number must contain 11 digits")
              .max(11, "The phone number must contain 11 digits")
              .required("Please Enter The Phone Number"),
            password: Yup.string()
              .min(8, "The password must be at least 8 characters")
              .required("Please Enter The Password"),
          }
        : {
            name: Yup.string(),
            email: Yup.string(),
            phone: Yup.string(),
          }
    ),
    onSubmit: () => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    if (props.selectedCallCenter) {
      handleEdit();
    } else handleAdd();
  };
  const handleEdit = () => {
    axios
      .patch(
        `admins/editMake/${props.selectedCallCenter._id}`,
        {
          ...(props.callCenter.name != props.selectedCallCenter.name &&
            props.selectedCallCenter.name != "" && {
              name: props.selectedCallCenter.name,
            }),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        props.setSelectedCallCenter(null);
        props.setReload((prev) => !prev);
      })
      .catch((e) => {});
  };
  const handleAdd = () => {
    setLoadingSubmit(true);
    var fdata = new FormData();
    fdata.append("file", image);
    // fdata.append("name", "sehs")

    axios
      .post("/cover", fdata, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoadingSubmit(false);
        props.setReload((prev) => !prev);
      })
      .catch((e) => {
        setLoadingSubmit(false);
      });
  };

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          //   downloadQRCode()
          validation.handleSubmit();
        }}
      >
        <Row>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Cover
              </Label>
              <Input
                id="image"
                className="form-control"
                type="file"
                placeholder="Image"
                // value={
                //   props.selectedCallCenter
                //     ? props.selectedCallCenter.name
                //     : image
                // }
                onChange={qrCodeEncoder}
              />
              {validation.touched.image && validation.errors.image ? (
                <FormFeedback type="invalid">
                  {validation.errors.image}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
            onClick={handleSubmit}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
AddEditCallCenter.propTypes = {
  callCenter: PropTypes.any,
  selectedCallCenter: PropTypes.any,
  setCallCenter: PropTypes.any,
  setSelectedCallCenter: PropTypes.any,
  setReload: PropTypes.any,
};

export default AddEditCallCenter;
