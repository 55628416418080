/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios/axios";
import "react-datepicker/dist/react-datepicker.css";

const AddEditCallCenter = ({
  selectedLoyaltyCard,
  setSelectedLoyaltyCard,
  setReload,
}) => {
  const initialValues = {
    discount: 0,
    numberOfCards: 1,
    duration: 1,
  };
  const [formData, setFormData] = useState(initialValues);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (selectedLoyaltyCard) {
      setFormData({
        _id: selectedLoyaltyCard._id,
        discount: selectedLoyaltyCard.discount,
        duration: selectedLoyaltyCard.duration,
      });
    }
  }, [selectedLoyaltyCard]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      discount: Yup.number().required("Please enter the discount"),
      numberOfCards: Yup.number().required("Please enter number of cards"),
      duration: Yup.number().required("Please enter the duration"),
    }),
    onSubmit: () => {
      if (selectedLoyaltyCard) {
        handleEdit();
      } else {
        handleAdd();
      }
    },
  });

  const handleEdit = () => {
    axios
      .patch(
        `/loyaltyCards/${selectedLoyaltyCard._id}`,
        { discount: formData.discount, duration: formData.duration },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setSelectedLoyaltyCard(null);
        setFormData(initialValues);
        setReload((prev) => !prev);
      })
      .catch((e) => {});
  };

  const handleAdd = () => {
    setError("");
    if (formData.discount > 100) {
      setError("Discount can't be greater than 100%");
      return;
    }
    if (formData.discount < 0) {
      setError("Discount can't be less than 0%");
      return;
    }
    setLoadingSubmit(true);
    axios
      .post("/loyaltyCards", formData, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((_) => {
        setLoadingSubmit(false);
        setReload((prev) => !prev);
      })
      .catch((e) => {
        setError(e.response.data?.message);
        setLoadingSubmit(false);
      });
  };

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
      >
        {error && <Alert color="danger">{error}</Alert>}
        <Row>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label
                htmlFor="discount"
                style={{ color: "#5B626B", display: "block" }}
              >
                Discount
              </Label>
              <OutlinedInput
                maxLength={75}
                style={{ height: "4.5vh", width: "35vw" }}
                id="discount"
                name="discount"
                className="form-control"
                type="number"
                placeholder="Enter the discount percentage"
                value={formData.discount}
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue > 100) {
                    newValue = 100;
                  }
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: newValue,
                  }));
                }}
                endAdornment={
                  <InputAdornment position="end" color="primary">
                    {" "}
                    {"%"}
                  </InputAdornment>
                }
              />
              {validation.touched.discount && validation.errors.discount ? (
                <FormFeedback type="invalid">
                  {validation.errors.discount}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label
                htmlFor="duration"
                style={{ color: "#5B626B", display: "block" }}
              >
                Duration
              </Label>
              <OutlinedInput
                maxLength={75}
                style={{ height: "4.5vh", width: "35vw" }}
                id="duration"
                className="form-control"
                type="number"
                placeholder="Enter the duration"
                value={formData.duration}
                name="duration"
                onChange={(e) => {
                  let newValue = e.target.value;
                  if (newValue < 1) {
                    newValue = 1;
                  }
                  setFormData((prev) => ({
                    ...prev,
                    [e.target.name]: newValue,
                  }));
                }}
                endAdornment={
                  <InputAdornment position="end" color="primary">
                    {" "}
                    {"days"}
                  </InputAdornment>
                }
              />
              {validation.touched.duration && validation.errors.duration ? (
                <FormFeedback type="invalid">
                  {validation.errors.duration}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          {!selectedLoyaltyCard && (
            <Col xs={12} md={6}>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="numberOfCards"
                  style={{ color: "#5B626B", display: "block" }}
                >
                  Number of Cards
                </Label>
                <OutlinedInput
                  maxLength={75}
                  style={{ height: "4.5vh", width: "35vw" }}
                  id="numberOfCards"
                  name="numberOfCards"
                  className="form-control"
                  type="number"
                  placeholder="Enter number of cards"
                  value={formData.numberOfCards}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue < 1) {
                      newValue = 1;
                    }
                    setFormData((prev) => ({
                      ...prev,
                      [e.target.name]: newValue,
                    }));
                  }}
                />
                {validation.touched.numberOfCards &&
                validation.errors.numberOfCards ? (
                  <FormFeedback type="invalid">
                    {validation.errors.numberOfCards}
                  </FormFeedback>
                ) : null}
              </FormGroup>
            </Col>
          )}
        </Row>
        <div className="col-12 d-flex gap-1">
          {selectedLoyaltyCard && (
            <button
              className="btn btn-secondary"
              type={"button"}
              onClick={() => {
                setSelectedLoyaltyCard(null);
                setFormData(initialValues);
              }}
            >
              Reset
            </button>
          )}
          <button className="btn btn-primary" type="submit">
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};

export default AddEditCallCenter;
