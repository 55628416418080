/* eslint-disable no-unused-vars */
import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <div className="col-12">
                            {/* © {new Date().getFullYear()} Veltrix
							<span className="d-none d-sm-inline-block">
								{" "}
								- Crafted with <i className="mdi mdi-heart text-danger"></i> by
								Themesbrand.
							</span> */}
                            <div
                                className="footer-copyright"
                                style={{ backgroundColor: "transparent" }}
                            >
                                <div
                                    className="container"
                                    // style={{ marginTop: "1px" }}
                                >
                                    <div
                                        className="row"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div
                                            className="col-md-12"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <p
                                                style={{
                                                    margin: "0",
                                                    fontSize: "15px",
                                                    lineHeight: "1.8",
                                                    fontWeight: "700",
                                                    fontFamily: "Segoe UI",
                                                    color: "#000",
                                                }}
                                            >
                                                Copyright &copy;{" "}
                                                {new Date().getFullYear()} by{" "}
                                                <span>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href="https://neoneg.com/"
                                                        style={{
                                                            cursor: "pointer",
                                                            textDecoration:
                                                                "none",
                                                            color: "#000",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        NEON
                                                    </a>
                                                </span>
                                                <a
                                                    href=""
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={{
                                                        textDecoration: "none",
                                                        fontWeight: "700",
                                                        color: "#000",
                                                    }}
                                                    onClick={() => {
                                                        window.open(
                                                            `https://wa.me/+201113588988`
                                                        )
                                                    }}
                                                >
                                                    <i
                                                        className="fab fa-whatsapp fs-5"
                                                        style={{
                                                            color: "#000",
                                                            marginLeft: "10px",
                                                        }}
                                                    ></i>
                                                </a>{" "}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    )
}

export default Footer
