import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Alert,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";
import AddEditTutorial from "./AddEditGovernorate";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";
// import Pages404 from "pages/Utility/pages-404.js"
import Pages401 from "pages/Utility/pages-401.js";

const Tutorials = () => {
  const { id } = useParams();
  // Table columns
  const columns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Name (AR)",
      field: "nameAr",
    },
    {
      label: "Delivery Price",
      field: "delivery",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ];

  // Initial color values
  const initialColorValues = {
    name: "",
    nameAr: "",
  };

  // States
  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState(initialColorValues);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [addEditErrorMsg, setAddEditErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setError(false);
    setLoading(true);
    setErrorMsg("");
    axios
      .get(`zones/${id}/governorates`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setError(false);
        setLoading(false);
        setColors(res.data.governorates);
      })
      .catch((e) => {
        setLoading(false);
        setErrorMsg(e.response.data?.message);
      });
  }, [reload]);

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this governorate, do you wish to continue?"
    );
    if (confirm) {
      axios
        .delete(`zones/governorates/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((_) => {
          setReload(!reload);
        })
        .catch((e) => {
          setLoading(false);
          setErrorMsg(e.response.data?.message);
        });
    }
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container
          fluid
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={"lg"} color="primary" />
        </Container>
      </div>
    </React.Fragment>
  ) : error ? (
    <Pages401 />
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Page title */}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Governorates</h5>
              </Col>
            </Row>
          </div>
          {/* Add Edit Tutorial */}
          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu);
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {edit ? "Edit Color" : "Add Color"}
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditTutorial
                    edit={edit}
                    setEdit={setEdit}
                    setMenu={setMenu}
                    initialColorValues={initialColorValues}
                    color={color}
                    setColor={setColor}
                    setReload={setReload}
                    errorMsg={addEditErrorMsg}
                    setErrorMsg={setAddEditErrorMsg}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>
          {/* Colors list */}
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Governorates List</h6>
                </CardHeader>
                <CardBody>
                  {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: colors.map((one) => {
                        return {
                          ...one,
                          delivery: one.delivery,
                          actions: (
                            <>
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setError("");
                                  setAddEditErrorMsg("");
                                  if (edit) {
                                    setMenu(false);
                                    setEdit(false);
                                    setColor(initialColorValues);
                                  } else {
                                    setMenu(true);
                                    setEdit(true);
                                    setColor(one);
                                  }
                                }}
                              >
                                {edit ? (
                                  <i className="fas fa-close"></i>
                                ) : (
                                  <i className="fas fa-edit"></i>
                                )}
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-danger btn-sm ms-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setError("");
                                  setAddEditErrorMsg("");
                                  handleDelete(one._id);
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </Link>
                            </>
                          ),
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Tutorials.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Tutorials);
