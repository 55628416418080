import PropTypes from "prop-types";
import { useState } from "react";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios/axios";

const AddEditTutorial = ({
  edit,
  setEdit,
  setMenu,
  color,
  setColor,
  setReload,
  errorMsg,
  setErrorMsg,
  initialColorValues,
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  // Validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: color,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values) => {
    setErrorMsg("");
    setLoadingSubmit(true);
    if (edit) {
      axios
        .patch(`zones/countries/${values._id}`, {
          name: values.name,
          nameAr: values.nameAr,
        }, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((_) => {
          setLoadingSubmit(false);
          setReload((prev) => !prev);
          setColor(initialColorValues);
          setEdit(false);
          setMenu(false);
        })
        .catch((e) => {
          setErrorMsg(e.response.data?.message || "Something went wrong");
          setLoadingSubmit(false);
        });
    } else {
      axios
        .post("zones/countries", values, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((_) => {
          setLoadingSubmit(false);
          setReload((prev) => !prev);
          setColor(initialColorValues);
        })
        .catch((e) => {
          setErrorMsg(e.response.data?.message || "Something went wrong");
          setLoadingSubmit(false);
        });
    }
  };

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row style={{ width: "100%" }}>
          {errorMsg && (
            <Alert className="mt-3" color="danger">
              {errorMsg}
            </Alert>
          )}
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
              <Input
                required={true}
                id="name"
                className="form-control"
                type="text"
                placeholder="Name"
                value={color.name}
                onChange={(e) => {
                  setColor((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                  validation.handleChange(e);
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                {"Name (AR)"}
              </Label>
              <Input
                required={true}
                id="nameAr"
                className="form-control"
                type="text"
                placeholder="Name (AR)"
                value={color.nameAr}
                onChange={(e) => {
                  setColor((prev) => ({
                    ...prev,
                    nameAr: e.target.value,
                  }));
                  validation.handleChange(e);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="col-12">
          <button className="btn btn-primary" type={"submit"}>
            {loadingSubmit ? <Spinner size={"sm"} color="secondary" /> : "Submit"}
          </button>
        </div>
      </Form>
    </>
  );
};

AddEditTutorial.propTypes = {
  color: PropTypes.any,
  setColor: PropTypes.any,
  setReload: PropTypes.any,
};

export default AddEditTutorial;
