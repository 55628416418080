import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";

//i18n
import { withTranslation } from "react-i18next";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";
// import Pages404 from "pages/Utility/pages-404.js"
import Pages401 from "pages/Utility/pages-401.js";

const Tutorials = () => {
  // Table columns
  const columns = [
    {
      label: "Product Name",
      field: "prod_name",
    },
    {
      label: "Review",
      field: "review",
    },
    {
      label: "Rate",
      field: "rating",
    },
    {
      label: "User email",
      field: "user_email",
    },
    {
      label: "Created At",
      field: "createdAt",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ];

  // States
  const [reviews, setReviews] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setErrorMsg("");
    axios
      .get("reviews/pending", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => setReviews(res.data.reviews))
      .catch((e) => setErrorMsg(e.response.data?.message))
      .finally((_) => setLoading(false));
  }, []);

  const acceptReview = (id) => {
    setLoading(true);
    axios
      .patch(
        `/reviews/accept/${id}`,
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((_) => setReviews((prev) => prev.filter((rev) => rev._id !== id)))
      .catch((e) => setErrorMsg(e.response.data?.message))
      .finally((_) => setLoading(false));
  };

  const rejectReview = (id) => {
    setLoading(true);
    axios
      .delete(
        `reviews/reject/${id}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((_) => setReviews((prev) => prev.filter((rev) => rev._id !== id)))
      .catch((e) => setErrorMsg(e.response.data?.message))
      .finally((_) => setLoading(false));
  };

  return loading ? (
    <React.Fragment>
      <div className="page-content">
        <Container
          fluid
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={"lg"} color="primary" />
        </Container>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Page title */}
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Reviews</h5>
              </Col>
            </Row>
          </div>
          {/* reviews list */}
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Reviews List</h6>
                </CardHeader>
                <CardBody>
                  {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: reviews.map((one) => {
                        return {
                          ...one,
                          prod_id: one.product._id,
                          prod_name: one.product.name,
                          user_id: one.user._id,
                          user_email: one.user.email,
                          user_name:
                            one.user.firstName + " " + one.user.lastName,
                          actions: (
                            <>
                              <Link
                                to="#"
                                className="btn btn-danger btn-sm"
                                onClick={(_) => rejectReview(one._id)}
                              >
                                <i className="fas fa-close"></i>
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-success btn-sm ms-2"
                                onClick={(_) => acceptReview(one._id)}
                              >
                                <i className="fas fa-check"></i>
                              </Link>
                            </>
                          ),
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Tutorials.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Tutorials);
