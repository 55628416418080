/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import withRouter from "components/Common/withRouter"

import { logoutUser } from "../../store/actions"
//redux
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

const Logout = (props) => {
	useEffect(() => {
		sessionStorage.clear()
		window.location.replace("/")
	}, [])

	return <></>
}

export default Logout
