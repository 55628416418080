/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Row,
  Spinner,
  Alert,
} from "reactstrap"
import OutlinedInput from "@mui/material/OutlinedInput"
import InputAdornment from "@mui/material/InputAdornment"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios/axios"
// import format from "date-fns/"
import { format } from "date-fns"

import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
// import { SelectionState } from "draft-js"
// import { QRCodeCanvas } from "qrcode.react"

const AddEditCallCenter = (props) => {
  const [Promo, setPromo] = useState("")

  const qrCodeEncoder = (e) => {
    setPromo(Math.abs(e))
  }
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [endAt, setEndAt] = useState()
  const [error, setError] = useState()

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: props.callCenter,
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter The Name"),
      email: Yup.string().required("Please Enter The Email"),
      phone: Yup.string()
        .min(11, "The phone number must contain 11 digits")
        .max(11, "The phone number must contain 11 digits")
        .required("Please Enter The Phone Number"),
      password: Yup.string()
        .min(8, "The password must be at least 8 characters")
        .required("Please Enter The Password"),
    }),
    onSubmit: () => {
      handleSubmit()
    },
  })

  const handleSubmit = () => {
    if (props.selectedCallCenter) {
      handleEdit()
    } else handleAdd()
  }
  const handleEdit = () => {
    axios
      .patch(
        `admins/editMake/${props.selectedCallCenter._id}`,
        {
          ...(props.callCenter.name != props.selectedCallCenter.name &&
            props.selectedCallCenter.name != "" && {
              name: props.selectedCallCenter.name,
            }),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        props.setSelectedCallCenter(null)
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
      })
  }
  const handleAdd = () => {
    setError("")
    if (Promo > 100) {
      setError("Promo code value can't be greater than 100%")
      return
    }
    const currentDate = new Date()
    const isFutureDate = new Date(endAt) > currentDate
    if (!isFutureDate) {
      setError("The selected date is in the past")
      return
    }
    setLoadingSubmit(true)
    axios
      .post(
        "/promocodes/add",
        {
          value: Promo,
          ...(endAt && { endAt: format(new Date(endAt), "yyyy-MM-dd") }),
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoadingSubmit(false)
        setPromo(0)
        setEndAt("")
        props.setReload((prev) => !prev)
      })
      .catch((e) => {
        setError(e.response.data?.message)
        setLoadingSubmit(false)
      })
  }

  return (
    <>
      <Form
        className="row g-3 needs-validation"
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault()
          validation.handleSubmit()
        }}
      >
        {error && <Alert color="danger">{error}</Alert>}
        <Row>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Percentage
              </Label>
              <OutlinedInput
                maxLength={75}
                style={{ height: "4.5vh", width: "35vw" }}
                id="Promo"
                className="form-control"
                type="number"
                placeholder="Enter Value of Promo Code"
                value={Promo}
                onChange={(e) => {
                  // Validate input to be less than or equal to 100
                  let newValue = e.target.value
                  if (newValue > 100) {
                    newValue = 100 // Set value to 100 if it exceeds 100
                  }
                  qrCodeEncoder(newValue)
                }}
                endAdornment={
                  <InputAdornment position="end" color="primary">
                    {" "}
                    {"%"}
                  </InputAdornment>
                }
              />

              {/* <Input

              // value={
              //   props.selectedCallCenter
              //     ? props.selectedCallCenter.name
              //     : Promo
              // }
              /> */}
              {validation.touched.Promo && validation.errors.Promo ? (
                <FormFeedback type="invalid">
                  {validation.errors.Promo}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                End At
              </Label>
              <DatePicker
                // required
                className="form-control"
                dateFormat="yyyy-MM-dd"
                placeholder="end in 1 month"
                selected={endAt}
                onChange={(value) => {
                  setEndAt(value)
                }}
              />
              {validation.touched.endAt && validation.errors.endAt ? (
                <FormFeedback type="invalid">
                  {validation.errors.endAt}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col>
          {/* <Col xs={12} md={6}>
            <FormGroup className="mt-3 mb-3">
              <Label htmlFor="example-text-input1" style={{ color: "#5B626B" }}>
                value
              </Label>
              <Input
                required
                id="Promo"
                className="form-control"
                placeholder="enter Value"
                type="number"
                value={valuee}
                // selected={}
                onChange={(value) => {
                  setValue(value)
                }}
              />
              {validation.touched.value && validation.errors.value ? (
                <FormFeedback type="invalid">
                  {validation.errors.value}
                </FormFeedback>
              ) : null}
            </FormGroup>
          </Col> */}
        </Row>
        <div className="col-12">
          <button
            className="btn btn-primary"
            type={loadingSubmit ? null : "submit"}
            onClick={handleSubmit}
          >
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  )
}
AddEditCallCenter.propTypes = {
  callCenter: PropTypes.any,
  selectedCallCenter: PropTypes.any,
  setCallCenter: PropTypes.any,
  setSelectedCallCenter: PropTypes.any,
  setReload: PropTypes.any,
}

export default AddEditCallCenter
