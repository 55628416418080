/* eslint-disable react/no-unescaped-entities */
const layoutTypes = {
	HORIZONTAL: "horizontal",
	VERTICAL: "vertical",
}

const layoutWidth = {
	FLUID: "fluid",
	BOXED: "boxed",
	SCROLLABLE: "scrollable",
}

const layoutPositionTypes = {
	FIXED: "false",
	SCROLLABLE: "true",
}

const topbarTheme = {
	LIGHT: "light",
	DARK: "dark",
}

const leftSideBarType = {
	DEFAULT: "default",
	COMPACT: "md",
	ICON: "sm",
}

const leftSideBarTheme = {
	LIGHT: "light",
	DARK: "dark",
	COLORED: "colored",
}

const themeModeTypes = {
	LIGHT: "light",
	DARK: "dark",
}

export {
	layoutTypes,
	themeModeTypes,
	layoutWidth,
	layoutPositionTypes,
	topbarTheme,
	leftSideBarType,
	leftSideBarTheme,
}
