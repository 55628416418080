/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";
import AddEditBlog from "./AddEditBlog.js";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";

const Blogs = () => {
  const columns = [
    // {
    // 	label: "ID",
    // 	field: "_id",
    // 	sort: "asc",
    // },
    {
      label: "Image",
      field: "img",
      sort: "asc",
    },
    {
      label: "Title EN",
      field: "titleEnglish",
      sort: "asc",
    },
    {
      label: "Title AR",
      field: "titleArabic",
      sort: "asc",
    },
  ];
  const blogInitial = {
    titleEnglish: "",
    descriptionEnglish: "",
    bodyEnglish: "",
    titleArabic: "",
    descriptionArabic: "",
    bodynArabic: "",
    img: null,
  };
  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blog, setBlog] = useState(blogInitial);
  const [selectedBlog, setSelectedBlog] = useState();
  document.title = "Blogs";

  useEffect(() => {
    axios
      .get("blogs", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setBlogs(
          res.data?.blogs?.map((one) => {
            return {
              _id: one._id,
              titleEnglish: one.titleEnglish,
              titleArabic: one.titleArabic,
              descriptionEnglish: one.descriptionEnglish,
              descriptionArabic: one.descriptionArabic,
              bodyEnglish: one.bodyEnglish,
              bodyArabic: one.bodyArabic,
              img: one.image,
            };
          })
        );
      })
      .catch((e) => {});
  }, [reload]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Blogs</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu);
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {!selectedBlog ? "Add" : "Edit"} Blog
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditBlog
                    blog={blog}
                    selectedBlog={selectedBlog}
                    setBlog={setBlog}
                    setSelectedBlog={setSelectedBlog}
                    setReload={setReload}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Blogs List</h6>
                </CardHeader>
                <CardBody>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: blogs.map((one) => {
                        return {
                          ...one,
                          img: (
                            <img
                              src={one.img}
                              alt="image"
                              style={{ width: "5vw" }}
                            ></img>
                          ),
                          clickEvent: () => {
                            if (selectedBlog && selectedBlog._id === one._id) {
                              setBlog(blogInitial);
                              setSelectedBlog(null);
                            } else {
                              setBlog({
                                _id: one._id,
                                titleEnglish: one.titleEnglish
                                  ? one.titleEnglish
                                  : "",
                                descriptionEnglish: one.descriptionEnglish
                                  ? one.descriptionEnglish
                                  : "",
                                titleArabic: one.titleArabic
                                  ? one.titleArabic
                                  : "",
                                descriptionArabic: one.descriptionArabic
                                  ? one.descriptionArabic
                                  : "",
                                bodyArabic: one.bodyArabic
                                  ? one.bodyArabic
                                  : "",
                                bodyEnglish: one.bodyEnglish
                                  ? one.bodyEnglish
                                  : "",
                                img: one.img,
                              });
                              setSelectedBlog({
                                _id: one._id,
                                titleEnglish: one.titleEnglish
                                  ? one.titleEnglish
                                  : "",
                                descriptionEnglish: one.descriptionEnglish
                                  ? one.descriptionEnglish
                                  : "",
                                titleArabic: one.titleArabic
                                  ? one.titleArabic
                                  : "",
                                descriptionArabic: one.descriptionArabic
                                  ? one.descriptionArabic
                                  : "",
                                img: one.img,
                              });
                              setMenu(true);
                            }
                            window.scrollTo({
                              top: 0,
                              behavior: "smooth",
                            });
                          },
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Blogs.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Blogs);
