/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
    Alert,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import axios from "axios/axios"

const AddEditCallCenter = (props) => {
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    // const [error, setError] = useState({})
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: props.callCenter,
        validationSchema: Yup.object(
            !props.selectedCallCenter
                ? {
                      name: Yup.string().required("Please Enter The Name"),
                      email: Yup.string().required("Please Enter The Email"),
                      phone: Yup.string()
                          .min(11, "The phone number must contain 11 digits")
                          .max(11, "The phone number must contain 11 digits")
                          .required("Please Enter The Phone Number"),
                      password: Yup.string()
                          .min(8, "The password must be at least 8 characters")
                          .required("Please Enter The Password"),
                  }
                : {
                      name: Yup.string(),
                      email: Yup.string(),
                      phone: Yup.string(),
                  }
        ),
        onSubmit: (values) => {
            handleSubmit(values)
        },
    })

    const handleSubmit = (values) => {
        if (props.selectedCallCenter) {
            handleEdit(values)
        } else {
            handleAdd(values)
        }
    }
    const handleAdd = (values) => {
        props.setError("")
        setLoadingSubmit(true)
        axios
            .post(
                "admins/createCallCenter",
                {
                    name: values.name,
                    email: values.email,
                    password: values.password,
                    phone: values.phone,
                },
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                }
            )
            .then((res) => {
                setLoadingSubmit(false)
                props.setReload((prev) => !prev)
                props.setCallCenter({
                    name: "",
                    email: "",
                    phone: "",
                    password: "",
                })
            })
            .catch((e) => {
                props.setError(e.response.data?.message)
                setLoadingSubmit(false)
            })
    }
    const handleEdit = (values) => {
        props.setError("")
        setLoadingSubmit(true)
        axios
            .patch(
                `callCenter/edit/${props.selectedCallCenter._id}`,
                {
                    ...(values.name != props.selectedCallCenter.name && {
                        name: values.name,
                    }),
                    ...(values.email != props.selectedCallCenter.email && {
                        email: values.email,
                    }),
                    ...(values.phone != props.selectedCallCenter.phone && {
                        phone: values.phone,
                    }),
                },
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                }
            )
            .then((res) => {
                setLoadingSubmit(false)
                props.setReload((prev) => !prev)
                props.setCallCenter({
                    name: "",
                    email: "",
                    phone: "",
                    password: "",
                })
                props.setSelectedCallCenter(null)
            })
            .catch((e) => {
                props.setError(e.response.data?.message)
                setLoadingSubmit(false)
            })
    }

    return (
        <>
            <Form
                className="row g-3 needs-validation"
                style={{ width: "100%" }}
                onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                }}
            >
                <Row>
                    { props.error && <Alert className="mt-3" color="danger">{props.error}</Alert> }
                    <Col xs={12} md={6}>
                        <FormGroup className="mt-3 mb-3">
                            <Label
                                htmlFor="example-text-input"
                                style={{ color: "#5B626B" }}
                            >
                                Name
                            </Label>
                            <Input
                                id="name"
                                className="form-control"
                                type="text"
                                placeholder="Name"
                                value={props.callCenter.name}
                                invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    props.setCallCenter({
                                        ...props.callCenter,
                                        name: e.target.value,
                                    })
                                    validation.handleChange(e)
                                }}
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.name}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup className="mt-3 mb-3">
                            <Label
                                htmlFor="example-text-input"
                                style={{ color: "#5B626B" }}
                            >
                                Email
                            </Label>
                            <Input
                                id="email"
                                className="form-control"
                                type="text"
                                placeholder="Email"
                                value={props.callCenter.email}
                                invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    props.setCallCenter({
                                        ...props.callCenter,
                                        email: e.target.value,
                                    })
                                    validation.handleChange(e)
                                }}
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.email}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup className="mb-3">
                            <Label
                                htmlFor="example-text-input"
                                style={{ color: "#5B626B" }}
                            >
                                Phone Number
                            </Label>
                            <Input
                                id="number"
                                className="form-control"
                                type="number"
                                placeholder="Phone Number"
                                value={props.callCenter.phone}
                                invalid={
                                    validation.touched.phone &&
                                    validation.errors.phone
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    props.setCallCenter({
                                        ...props.callCenter,
                                        phone: e.target.value,
                                    })
                                    validation.handleChange(e)
                                }}
                            />
                            {validation.touched.phone &&
                            validation.errors.phone ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.phone}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    {!props.selectedCallCenter && (
                        <Col xs={12} md={6}>
                            <FormGroup className="mb-3">
                                <Label
                                    htmlFor="example-text-input"
                                    style={{ color: "#5B626B" }}
                                >
                                    Password
                                </Label>
                                <Input
                                    id="password"
                                    className="form-control"
                                    type="password"
                                    placeholder="Password"
                                    value={props.callCenter.password}
                                    invalid={
                                        validation.touched.password &&
                                        validation.errors.password
                                            ? true
                                            : false
                                    }
                                    onChange={(e) => {
                                        props.setCallCenter({
                                            ...props.callCenter,
                                            password: e.target.value,
                                        })
                                        validation.handleChange(e)
                                    }}
                                />
                                {validation.touched.password &&
                                validation.errors.password ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.password}
                                    </FormFeedback>
                                ) : null}
                            </FormGroup>
                        </Col>
                    )}
                </Row>
                <div className="col-12">
                    <button
                        className="btn btn-primary"
                        type={loadingSubmit ? null : "submit"}
                    >
                        {loadingSubmit ? (
                            <Spinner size={"sm"} color="secondary" />
                        ) : (
                            "Submit"
                        )}
                    </button>
                </div>
            </Form>
        </>
    )
}
AddEditCallCenter.propTypes = {
    callCenter: PropTypes.any,
    selectedCallCenter: PropTypes.any,
    setCallCenter: PropTypes.any,
    setSelectedCallCenter: PropTypes.any,
    setReload: PropTypes.any,
}

export default AddEditCallCenter
