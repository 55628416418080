/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Spinner, Alert } from "reactstrap"
import { useParams } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios/axios.js"

const CallCenterVerification = () => {
    const [error, setError] = useState()
    const params = useParams()

    useEffect(() => {
        axios
            .post(`callCenter/verify/${params.token}`, {})
            .then(() => {
                window.location.replace("/")
            })
            .catch((e) => {
                setError("Something went wrong")
            })
            .finally(() => {})
    })

    document.title = "Admin | Verification"
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {error ? (
                        <Alert color="danger">{error}</Alert>
                    ) : (
                        <Spinner color="primary" size={"lg"} />
                    )}
                </Container>
            </div>
        </React.Fragment>
    )
}

CallCenterVerification.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(CallCenterVerification)
