/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import axios from "axios/axios";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Input, Label, Row, Spinner } from "reactstrap";
import { MDBDataTable } from "mdbreact";

const OrderDetails = ({ selectedOrder, setSelectedOrder, setReload }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleSubmit = () => {
    if (selectedOrder) {
      // handleEdit()
    } else {
      // handleAdd()
    }
  };

  const columns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Quantity",
      field: "quantity",
      sort: "asc",
    },
    {
      label: "Price",
      field: "price",
      sort: "asc",
    },
    {
      label: "Size",
      field: "size",
      sort: "asc",
    },
    {
      label: "Color",
      field: "color",
      sort: "asc",
    },
  ];

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col md={6} xs={12}>
          <Row className="mt-3 mb-2">
            <h4 htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              {format(new Date(selectedOrder.createdAt), "yyyy-MM-dd")}
            </h4>
          </Row>
          <Row className="mt-3 mb-2">
            <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              User
            </Label>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Name
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.user
                  ? selectedOrder.user.firstName +
                    " " +
                    selectedOrder.user.lastName
                  : ""}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Email
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.user && selectedOrder.user.email
                  ? selectedOrder.user.email
                  : ""}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Promo Code
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder?.promoCode?.code
                  ? selectedOrder?.promoCode?.code
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Payment Method
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder?.paymentMethod &&
                selectedOrder?.paymentMethod === "cod"
                  ? "Cash on delivery"
                  : selectedOrder?.paymentMethod === "card"
                  ? "Visa"
                  : ""}
              </p>
            </Col>
          </Row>
          <Row className="mt-3 mb-2">
            <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              Address
            </Label>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Apartment
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.address && selectedOrder.address.apartment
                  ? selectedOrder.address.apartment
                  : ""}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Floor
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.address && selectedOrder.address.floor
                  ? selectedOrder.address.floor
                  : ""}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Street
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.address && selectedOrder.address.street
                  ? selectedOrder.address.street
                  : ""}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Building
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.address && selectedOrder.address.building
                  ? selectedOrder.address.building
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                City
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.address && selectedOrder.address.city
                  ? selectedOrder.address.city
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Postal Code
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.address && selectedOrder.address.postalCode
                  ? selectedOrder.address.postalCode
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Phone
              </Label>
            </Col>
            <Col md={9}>
              <p style={{ color: "#5B626B" }}>
                {selectedOrder.phone ? selectedOrder.phone : ""}
              </p>
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={12}>
          <Row className="mt-3 mb-2">
            <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
              Products
            </Label>
          </Row>
          <Row className="mb-0" style={{ marginLeft: 3 }}>
            <MDBDataTable
              hover
              responsive
              noBottomColumns
              data={{
                columns: columns,
                rows: selectedOrder?.products?.map((one) => {
                  return {
                    ...one,
                    name: one?.product?.name,
                    quantity: one?.quantity,
                    price: one?.product?.variant?.price,
                    size: one?.product?.variant?.size
                      ? one?.product?.variant?.size
                      : "-",
                    color: one?.product?.variant?.color?.name
                      ? one?.product?.variant?.color?.name
                      : "-",
                  };
                }),
              }}
            />
          </Row>
          <Row className="mt-3 mb-2">
            <Col md={3}>
              <Label htmlFor="example-text-input" style={{ color: "#5B626B" }}>
                Status
              </Label>
            </Col>
            <Col md={5}>
              <Input
                required
                id="status"
                className="form-control"
                type="select"
                placeholder="Status"
                value={selectedOrder.status}
                onChange={(e) => {
                  setSelectedOrder({
                    ...selectedOrder,
                    status: e.target.value,
                  });
                }}
              >
                <option value="" label="Status" disabled />
                <option value="pending" label="Pending" />
                <option value="processing" label="Processing" />
                <option value="shipped" label="Shipped" />
                <option value="delivered" label="Delivered" />
                <option value="cancelled" label="Cancelled" />
              </Input>
            </Col>
            <Col
              md={4}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  setLoadingSubmit(true);
                  axios
                    .patch(
                      `orders/update/${selectedOrder._id}`,
                      {
                        status: selectedOrder.status,
                      },
                      {
                        headers: {
                          Authorization: sessionStorage.getItem("token"),
                        },
                      }
                    )
                    .then(() => {
                      setLoadingSubmit(false);
                      setReload((prev) => !prev);
                    })
                    .catch((e) => {
                      setLoadingSubmit(false);
                    });
                }}
              >
                {loadingSubmit ? (
                  <Spinner size={"sm"} color="secondary" />
                ) : (
                  "Save"
                )}
              </button>
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={12}>
          <Row className="mt-3 mb-2">
            <Label
              htmlFor="example-text-input"
              style={{ color: "#5B626B", fontSize: 25 }}
            >
              Total
            </Label>
            <p style={{ color: "#5B626B", fontSize: 30 }}>
              {selectedOrder.total
                ? selectedOrder.total.toFixed(2) + " EGP"
                : ""}
            </p>
          </Row>
        </Col>
      </Row>
    </>
  );
};
OrderDetails.propTypes = {
  selectedOrder: PropTypes.any,
  setSelectedOrder: PropTypes.any,
  setReload: PropTypes.any,
};

export default OrderDetails;
