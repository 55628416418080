/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useState } from "react"
import {
    Alert,
    Col,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Switch from "react-switch"
import axios from "axios/axios"

const AddEditAdmin = (props) => {
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [error, setError] = useState({})
    const [errorMsg, setErrorMsg] = useState("")

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: props.admin,
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter The Name"),
            email: Yup.string().required("Please Enter The Email"),
            password: Yup.string().required("Please Enter The Password"),
        }),
        onSubmit: (values) => {
            handleSubmit(values)
        },
    })

    const handleSubmit = (values) => {
        setLoadingSubmit(true)
        setError({})
        axios
            .post(
                "admins/createAdmin",
                {
                    name: values.name,
                    email: values.email,
                    password: values.password,
                },
                {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                }
            )
            .then( _ => {
                setLoadingSubmit(false)
                props.setReload((prev) => !prev)
                props.setAdmin({
                    email: "",
                    password: "",
                    name: "",
                })
            })
            .catch( e => {
                setErrorMsg(e.response.data?.message || "Something went wrong")
                setLoadingSubmit(false)
            })
    }

    return (
        <>
            <Form
                className="row g-3 needs-validation"
                style={{ width: "100%" }}
                onSubmit={(e) => {
                    e.preventDefault()
                    validation.handleSubmit()
                }}
            >
                { errorMsg && <Alert className="my-3 w-100" color="danger">{errorMsg}</Alert> }
                <Row style={{ width: "100%" }}>
                    <Col xs={12} md={6}>
                        <FormGroup className="mt-3 mb-3">
                            <Label
                                htmlFor="example-text-input"
                                style={{ color: "#5B626B" }}
                            >
                                Name
                            </Label>
                            <Input
                                id="name"
                                className="form-control"
                                type="text"
                                placeholder="Name"
                                value={props.admin.name}
                                invalid={
                                    (validation.touched.name &&
                                        validation.errors.name) ||
                                    error.name
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    props.setAdmin({
                                        ...props.admin,
                                        name: e.target.value,
                                    })
                                    validation.handleChange(e)
                                }}
                            />
                            {(validation.touched.name &&
                                validation.errors.name) ||
                            error.name ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.name || error.name}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup className="mt-3 mb-3">
                            <Label
                                htmlFor="example-text-input"
                                style={{ color: "#5B626B" }}
                            >
                                Email
                            </Label>
                            <Input
                                id="email"
                                className="form-control"
                                type="text"
                                placeholder="Email"
                                value={props.admin.email}
                                invalid={
                                    (validation.touched.email &&
                                        validation.errors.email) ||
                                    error.email
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    props.setAdmin({
                                        ...props.admin,
                                        email: e.target.value,
                                    })
                                    validation.handleChange(e)
                                }}
                            />
                            {(validation.touched.email &&
                                validation.errors.email) ||
                            error.email ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.email || error.email}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormGroup className="mb-3">
                            <Label
                                htmlFor="example-text-input"
                                style={{ color: "#5B626B" }}
                            >
                                Password
                            </Label>
                            <Input
                                id="password"
                                className="form-control"
                                type="password"
                                placeholder="Password"
                                value={props.admin.password}
                                invalid={
                                    validation.touched.password &&
                                    validation.errors.password
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    props.setAdmin({
                                        ...props.admin,
                                        password: e.target.value,
                                    })
                                    validation.handleChange(e)
                                }}
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                                <FormFeedback type="invalid">
                                    {validation.errors.password}
                                </FormFeedback>
                            ) : null}
                        </FormGroup>
                    </Col>
                </Row>

                <div className="col-12">
                    <button
                        className="btn btn-primary"
                        type={loadingSubmit ? null : "submit"}
                    >
                        {loadingSubmit ? (
                            <Spinner size={"sm"} color="secondary" />
                        ) : (
                            "Submit"
                        )}
                    </button>
                </div>
            </Form>
        </>
    )
}
AddEditAdmin.propTypes = {
    admin: PropTypes.any,
    setAdmin: PropTypes.any,
    setReload: PropTypes.any,
}

export default AddEditAdmin
