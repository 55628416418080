/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";
import AddEditBlog from "./AddEditStore.js";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";
import AddEditStore from "./AddEditStore.js";

const Stores = () => {
  const columns = [
    {
      label: "Name EN",
      field: "name_en",
      sort: "asc",
    },
    {
      label: "Name EN",
      field: "name_ar",
      sort: "asc",
    },
    {
      label: "Address EN",
      field: "address_en",
      sort: "asc",
    },
    {
      label: "Address AR",
      field: "address_ar",
      sort: "asc",
    },
    {
      label: "Location",
      field: "location",
      sort: "asc",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ];
  const storeInitial = {
    name_en: "",
    name_ar: "",
    address_en: "",
    address_ar: "",
    location: "",
    // bodynArabic: "",
    // img: null,
  };
  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [stores, setStores] = useState([]);
  const [store, setStore] = useState(storeInitial);
  const [selectedBlog, setSelectedBlog] = useState();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [addEditErrorMsg, setAddEditErrorMsg] = useState("");
  const [edit, setEdit] = useState(false);

  // document.title = "";

  useEffect(() => {
    axios
      .get("stores", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setStores(
          res.data?.map((one) => {
            return {
              id: one._id,
              name_en: one.name_en,
              name_ar: one.name_ar,
              address_en: one.address_en,
              address_ar: one.address_ar,
              location: one.location,
              // bodyArabic: one.bodyArabic,
              // img: one.image,
            };
          })
        );
      })
      .catch((e) => {});
  }, [reload]);
  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about delete this store, do you wish to continue?"
    );
    if (confirm) {
      axios
        .delete(`stores/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((_) => {
          setReload(!reload);
        })
        .catch((e) => {
          setLoading(false);
          setErrorMsg(e.response.data?.message);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Stores</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu);
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {!edit ? "Add" : "Edit"} Stores
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditStore
                    store={store}
                    edit={edit}
                    setEdit={setEdit}
                    setMenu={setMenu}
                    selectedBlog={selectedBlog}
                    setStore={setStore}
                    errorMsg={addEditErrorMsg}
                    setReload={setReload}
                    storeInitial={storeInitial}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Stores List</h6>
                </CardHeader>
                <CardBody>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: stores?.map((one) => {
                        return {
                          ...one,

                          actions: (
                            <>
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setError("");
                                  setAddEditErrorMsg("");
                                  if (edit) {
                                    setMenu(false);
                                    setEdit(false);
                                    setStore(storeInitial);
                                  } else {
                                    setMenu(true);
                                    setEdit(true);
                                    setStore(one);
                                  }
                                }}
                              >
                                {edit ? (
                                  <i className="fas fa-close"></i>
                                ) : (
                                  <i className="fas fa-edit"></i>
                                )}
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-danger btn-sm ms-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setError("");
                                  setAddEditErrorMsg("");
                                  handleDelete(one.id);
                                }}
                              >
                                <i className="fas fa-trash"></i>
                              </Link>
                            </>
                          ),
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Stores.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Stores);
