/* eslint-disable react/no-unknown-property */
/* eslint-disable no-mixed-spaces-and-tabs */
import axios from "axios/axios";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Alert,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddEditStore = ({
  edit,
  setEdit,
  store,
  setStore,
  setReload,
  setMenu,
  storeInitial,
}) => {
  console.log(store);
  console.log(edit);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [editing, setEditing] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: store,

    onSubmit: (values) => {
      console.log(values);
      handleSubmit(values);
    },
  });
  const handleSubmit = (values) => {
    setLoadingSubmit(true);
    setErrorMsg("");
    if (edit) {
      axios
        .patch(`stores`, values, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((_) => {
          setLoadingSubmit(false);
          setReload((prev) => !prev);
          setStore(storeInitial);
          setEdit(false);
          setMenu(false);
        })
        .catch((e) => setErrorMsg(e.response?.data?.message))
        .finally((_) => {
          setLoadingSubmit(false);
          setTimeout((_) => setErrorMsg(""), 3000);
        });
    } else {
      axios
        .post(
          `stores`,
          {
            name_en: values.name_en,
            name_ar: values.name_ar,
            address_en: values.address_en,
            address_ar: values.address_ar,
            location: values.location,
          },
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
            },
          }
        )
        .then((_) => window.location.reload())
        .catch((e) => setErrorMsg(e.response?.data?.message))
        .finally((_) => {
          setLoadingSubmit(false);
          setTimeout((_) => setErrorMsg(""), 3000);
        });
    }
  };

  return (
    <>
      <Form
        className="row g-3"
        style={{ width: "100%", marginTop: "1vh" }}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row style={{ width: "100%", margin: 0 }}>
          {errorMsg && <Alert color="danger">{errorMsg}</Alert>}
          <Col md={6} xs={12}>
            <Row>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Name EN
                </Label>

                <Input
                  id="nameEnglish"
                  className="form-control"
                  type="text"
                  name="name_en"
                  placeholder="Name EN"
                  value={store.name_en}
                  onChange={(e) => {
                    setStore((prev) => ({
                      ...prev,
                      name_en: e.target.value,
                    }));
                    validation.handleChange(e);
                  }}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Address EN
                </Label>

                <Input
                  id="addressEnglish"
                  className="form-control"
                  type="text"
                  rows="2"
                  name="address_en"
                  placeholder="Address EN"
                  value={store.address_en}
                  onChange={(e) => {
                    setStore((prev) => ({
                      ...prev,
                      address_en: e.target.value,
                    }));
                    validation.handleChange(e);
                  }}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="mt-3 mb-3">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  Location
                </Label>

                <Input
                  id="location"
                  className="form-control"
                  type="text"
                  rows="2"
                  name="location"
                  placeholder="location"
                  value={store.location}
                  onChange={(e) => {
                    setStore((prev) => ({
                      ...prev,
                      location: e.target.value,
                    }));
                    validation.handleChange(e);
                  }}
                />
              </FormGroup>
            </Row>
          </Col>
          <Col md={6} xs={12}>
            <Row>
              <FormGroup className="mt-3 mb-3" dir="rtl">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  الاسم
                </Label>
                <Input
                  id="nameArabic"
                  className="form-control"
                  type="text"
                  rows="2"
                  name="name_ar"
                  placeholder="الاسم"
                  value={store.name_ar}
                  onChange={(e) => {
                    setStore((prev) => ({
                      ...prev,
                      name_ar: e.target.value,
                    }));
                    validation.handleChange(e);
                  }}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="mt-3 mb-3" dir="rtl">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  العنوان
                </Label>
                <Input
                  id="addressArabic"
                  className="form-control"
                  type="text"
                  name="address_ar"
                  placeholder="العنوان"
                  value={store.address_ar}
                  onChange={(e) => {
                    setStore((prev) => ({
                      ...prev,
                      address_ar: e.target.value,
                    }));
                    validation.handleChange(e);
                  }}
                />
              </FormGroup>
            </Row>
            {/* <Row>
              <FormGroup className="mt-3 mb-3" dir="rtl">
                <Label
                  htmlFor="example-text-input"
                  style={{ color: "#5B626B" }}
                >
                  المحتوى
                </Label>
                {editing || !props.selectedStore ? (
                  <Input
                    id="bodyArabic"
                    className="form-control"
                    type="textarea"
                    rows="2"
                    placeholder="الوصف"
                    value={props.blog.bodyArabic}
                    onChange={(e) => {
                      props.setStore({
                        ...props.blog,
                        bodyArabic: e.target.value,
                      });
                    }}
                  />
                ) : (
                  <p style={{ color: "#5B626B" }}>
                    {props.selectedStore.bodyArabic
                      ? props.selectedStore.bodyArabic
                      : ""}
                  </p>
                )}
              </FormGroup>
            </Row> */}
          </Col>
        </Row>
        <div className="col-12">
          <button className="btn btn-primary" type={"submit"}>
            {loadingSubmit ? (
              <Spinner size={"sm"} color="secondary" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Form>
    </>
  );
};
AddEditStore.propTypes = {
  store: PropTypes.any,
  setStore: PropTypes.any,
  setReload: PropTypes.any,
};

export default AddEditStore;
