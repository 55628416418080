/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Spinner,
    Input,
    FormGroup,
    Form,
    Alert,
    Label,
} from "reactstrap"
import { Link, useParams } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"
import { format } from "date-fns"

const AdminResetPassword = () => {
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [error, setError] = useState()
    const params = useParams()

    const handleSubmit = () => {
        let password = document.getElementById("password").value
        setLoadingSubmit(true)
        axios
            .patch(`admins/resetPassword/${params.token}`, { password })
            .then((res) => {
                window.location.replace("/")
            })
            .catch((e) => {
                setError("Something went wrong")
            })
            .finally(() => {
                setLoadingSubmit(false)
            })
    }

    document.title = "Admin | Reset Password"
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Form
                        className="row g-3"
                        style={{ width: "100%", marginTop: "1vh" }}
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleSubmit()
                        }}
                    >
                        <Row style={{ width: "100%" }}>
                            <Col md={6} xs={12}>
                                {error && <Alert color="danger">{error}</Alert>}
                                <Row>
                                    <FormGroup className="mt-3 mb-3">
                                        <Label
                                            htmlFor="example-text-input"
                                            style={{ color: "#5B626B" }}
                                        >
                                            New Password
                                        </Label>
                                        <Input
                                            required
                                            id="password"
                                            className="form-control"
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </FormGroup>
                                </Row>
                            </Col>
                        </Row>

                        <div className="col-12">
                            <button
                                className="btn btn-primary"
                                type={loadingSubmit ? null : "submit"}
                            >
                                {loadingSubmit ? (
                                    <Spinner size={"sm"} color="secondary" />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </div>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    )
}

AdminResetPassword.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(AdminResetPassword)
