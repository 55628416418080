/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader, Alert } from "reactstrap";
import format from "date-fns/";
import { Link } from "react-router-dom";
import "chartist/dist/scss/chartist.scss";
import AddEditCallCenter from "./addEditPromos.js";
import "react-datepicker/dist/react-datepicker.css";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";

const CallCenter = () => {
  const columns = [
    {
      label: "Code",
      field: "code",
      sort: "asc",
    },
    {
      label: "Discount",
      field: "discount",
      sort: "asc",
    },
    {
      label: "Duration",
      field: "duration",
      sort: "asc",
    },
    {
      label: "No of uses",
      field: "noOfUses",
      sort: "asc",
    },
    {
      label: "User Name",
      field: "userName",
      sort: "asc",
    },
    {
      label: "User Email",
      field: "userEmail",
      sort: "asc",
    },
    {
      label: "Actions",
      field: "actions",
    },
  ];

  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState("");
  const [callCenters, setCallCenters] = useState([]);
  const [selectedLoyaltyCard, setSelectedLoyaltyCard] = useState(null);

  useEffect(() => {
    axios
      .get("/loyaltyCards", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCallCenters(res.data.loyaltyCards);
      })
      .catch((e) => {});
  }, [reload]);

  const handlePause = (id) => {
    axios
      .patch(
        `/loyaltyCards/togglePause/${id}`,
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setCallCenters( prev => 
          prev.map((one) => {
            if (one._id.toString() === id.toString())
              return res.data.loyaltyCard;
            return one;
          })
        );
      })
      .catch((err) => {
        setError(err.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Loyalty Cards</h5>
              </Col>
            </Row>
          </div>
          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu);
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {!selectedLoyaltyCard ? "Add" : "Edit"} Loyalty Card
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditCallCenter
                    selectedLoyaltyCard={selectedLoyaltyCard}
                    setSelectedLoyaltyCard={setSelectedLoyaltyCard}
                    setReload={setReload}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Loyalty Cards List</h6>
                </CardHeader>
                { error && <Alert color="danger">error</Alert> }
                <CardBody>
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: callCenters?.map((one, index) => {
                        return {
                          ...one,
                          userName: one.user
                            ? one.user.firstName + " " + one.user.lastName
                            : "None",
                          userEmail: one.user ? one.user.email : "None",
                          Value: one.value + "%",
                          actions: (
                            <Row>
                              <Col md={3} xs={3}>
                                {!one.user && (
                                  <Link
                                    to="#"
                                    className="btn btn-info btn-sm"
                                    style={{
                                      marginLeft: "0.2vw",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setMenu(true);
                                      setSelectedLoyaltyCard(one);
                                    }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </Link>
                                )}
                              </Col>
                              <Col md={3} xs={3}>
                                {
                                  <Link
                                    to="#"
                                    className={
                                      "btn btn-sm " +
                                      (one.paused
                                        ? "btn-danger"
                                        : "btn-success")
                                    }
                                    style={{
                                      marginLeft: "0.2vw",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handlePause(one._id);
                                    }}
                                  >
                                    {one.paused ? (
                                      <i className="fas fa-pause"></i>
                                    ) : (
                                      <i className="fas fa-play"></i>
                                    )}
                                  </Link>
                                }
                              </Col>
                            </Row>
                          ),
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CallCenter.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(CallCenter);
