/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-spaces-and-tabs*/
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  CardHeader,
  FormGroup,
  Label,
  Alert,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import { Link } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

import "chartist/dist/scss/chartist.scss";
import AddEditProduct from "./AddEditProduct.jsx";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";
import toast from "react-hot-toast";

const Products = () => {
  const columns = [
    {
      label: "Name",
      field: "name",
      sort: "asc",
    },
    {
      label: "Category",
      field: "category",
      sort: "asc",
    },
    {
      label: "Sub Category",
      field: "subCategory",
      sort: "asc",
    },
    {
      label: "Variants",
      field: "variantsNo",
      sort: "asc",
    },
    {
      label: "images",
      field: "Productimages",
    },
    {
      label: "",
      field: "actions",
    },
  ];
  const productInitial = {
    name: "",
    nameAr: "",
    description: "",
    descriptionAr: "",
    variants: [],
  };
  const [sale, setSale] = useState();
  const [modal, setModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState({});
  const handleCloseStd1 = () => setModal(!modal);
  const [prodImages, setProdImgs] = useState();
  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [product, setProduct] = useState(productInitial);
  const [selectedProduct, setSelectedProduct] = useState();
  const [catBol, setCatCol] = useState(false);
  const [subCatBol, setSubCatCol] = useState(false);
  const [editing, setEditing] = useState(false);
  const [saleProduct, setSaleProduct] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingSubmit1, setLoadingSubmit1] = useState(false);
  const [Error, setError] = useState("");

  const handleAddSale = () => {
    setLoadingSubmit(true);
    axios
      .patch(
        "products/addSale",
        {
          saleValue: sale,
          products: saleProduct,
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoadingSubmit(false);
        setReload(!reload);
      })
      .catch((e) => {
        setError(e);
        setLoadingSubmit(false);
      });
  };

  const handleAddRemove = (id) => {
    setLoadingSubmit1(true);
    axios
      .patch(
        `products/removeProductSale/${id}`,
        {},
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        setLoadingSubmit1(false);
        setReload(!reload);
      })
      .catch((e) => {
        setError(e);
        setLoadingSubmit1(false);
      });
  };

  useEffect(() => {
    axios
      .get("products/all", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setProducts(res.data.products);
        setReload(false);
      })
      .catch((e) => {});
  }, [reload]);

  const deleteProduct = (id) => {
    setLoadingDelete((prevState) => ({ ...prevState, [id]: true }));
    axios
      .delete(`products/deleteProduct/${id}`, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        toast.success(res?.data?.message);
        setReload(true);
      })
      .catch((err) => {
        toast.error(err?.res?.data?.message);
      })
      .finally(() => {
        setLoadingDelete((prevState) => ({ ...prevState, [id]: false }));
      });
  };

  const removeProductSelection = (e) => {
    e.preventDefault();
    setProduct(productInitial);
    setEditing(false);
    setSelectedProduct(null);
    setMenu(false);
    setShow(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Products</h5>
              </Col>
            </Row>
          </div>
          {/* ========= Add/Edit Product ========= */}
          {(sessionStorage.getItem("admin") || (show && selectedProduct)) && (
            <Row className="mb-4">
              <Col>
                <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                  <AccordionSummary
                    style={{ backgroundColor: "#E9ECEF" }}
                    onClick={() => {
                      setMenu(!menu);
                    }}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h6
                      style={{
                        fontWeight: "bold",
                        color: "#5B626B",
                      }}
                    >
                      {sessionStorage.getItem("admin")
                        ? (!selectedProduct ? "Add" : "Edit") + " Product"
                        : "Show Product"}
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AddEditProduct
                      catBol={catBol}
                      show={show}
                      setShow={setShow}
                      setCatCol={setCatCol}
                      subCatBol={subCatBol}
                      setSubCatCol={setSubCatCol}
                      product={product}
                      removeProductSelection={removeProductSelection}
                      editing={editing}
                      setEditing={setEditing}
                      selectedProduct={selectedProduct}
                      setProduct={setProduct}
                      setSelectedProduct={setSelectedProduct}
                      setReload={setReload}
                    />
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>
          )}
          {/* ========= Product List ========= */}
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Products List</h6>
                </CardHeader>
                <CardBody>
                  {/* Error */}
                  {Error && <Alert color="danger">{Error}</Alert>}
                  {/* Table */}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: products.map((one, index) => {
                        return {
                          ...one,
                          variantsNo: one.variants?.length,
                          category:
                            one.subCategory && one.subCategory.category
                              ? one.subCategory.category.name
                              : "-",
                          subCategory: one.subCategory && one.subCategory.name,
                          Productimages: (
                            <Row>
                              <Col>
                                <i
                                  className="fas fa-image fs-3"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setProdImgs([...one.images]);
                                    setModal(true);
                                  }}
                                ></i>
                              </Col>
                            </Row>
                          ),
                          actions: (
                            <Row>
                              {" "}
                              <Col md={6}>
                                {sessionStorage.getItem("admin") ? (
                                  <Link
                                    to="#"
                                    className="btn btn-primary btn-sm"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (
                                        selectedProduct &&
                                        selectedProduct._id === one._id
                                      ) {
                                        setProduct(productInitial);
                                        setEditing(false);
                                        setSelectedProduct(null);
                                        setMenu(false);
                                      } else {
                                        setProduct({
                                          ...one,
                                          variants: one.variants.map(
                                            (variant, index) => ({
                                              ...variant,
                                              id: index + 1,
                                              stock: variant.stock,
                                              size: variant.size,
                                              price: variant.price,
                                            })
                                          ),
                                        });
                                        // setCategory(one.product.subCategory.category)
                                        setEditing(true);
                                        setCatCol(true);
                                        setSubCatCol(true);
                                        setSelectedProduct({
                                          ...one,
                                          variants: one.variants.map(
                                            (variant, index) => ({
                                              ...variant,
                                              id: index + 1,
                                              stock: variant.stock,
                                              size: variant.size,
                                              price: variant.price,
                                            })
                                          ),
                                        });
                                        setMenu(true);
                                      }
                                    }}
                                  >
                                    {selectedProduct &&
                                    selectedProduct._id === one._id ? (
                                      <i
                                        className="ion ion-md-close"
                                        style={{
                                          color: "white",
                                        }}
                                      ></i>
                                    ) : (
                                      <i className="fas fa-edit"></i>
                                    )}
                                  </Link>
                                ) : (
                                  <></>
                                )}
                              </Col>
                              <Col md={2}>
                                <Link
                                  to="#"
                                  className="btn btn-info btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    {
                                      setProduct({
                                        ...one,
                                        variants: one.variants.map(
                                          (variant, index) => ({
                                            ...variant,
                                            id: index + 1,
                                            stock: variant.stock,
                                            size: variant.size,
                                            price: variant.price,
                                          })
                                        ),
                                      });
                                      setShow(true);
                                      setEditing(false);
                                      setCatCol(true);
                                      setSubCatCol(true);
                                      setSelectedProduct({
                                        ...one,
                                        variants: one.variants.map(
                                          (variant, index) => ({
                                            ...variant,
                                            id: index + 1,
                                            stock: variant.stock,
                                            size: variant.size,
                                            price: variant.price,
                                          })
                                        ),
                                      });
                                      setMenu(true);
                                    }
                                  }}
                                >
                                  <i className="fas fa-info"></i>
                                </Link>
                              </Col>
                              <Col md={2}>
                                {sale && !one.isSale && (
                                  <i
                                    className="fas fa-check"
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      let SaleProducts = [];
                                      SaleProducts = saleProduct;
                                      SaleProducts.push(one._id);
                                      setSaleProduct(SaleProducts);
                                      let product = [];
                                      product = products;
                                      product[index].isSale = true;
                                      setProducts([...product]);
                                    }}
                                  ></i>
                                )}
                                {one.isSale && (
                                  <>
                                    {loadingSubmit1 ? (
                                      <Spinner size={"sm"} color="secondary" />
                                    ) : (
                                      <i
                                        className="fas fa-x"
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (!sale) {
                                            handleAddRemove(one._id);
                                          } else {
                                            setError(
                                              "Clear Sale Input to delete product"
                                            );
                                          }
                                        }}
                                      ></i>
                                    )}
                                  </>
                                )}
                              </Col>
                              <Col md={2}>
                                <button
                                  className="btn btn-danger btn-sm"
                                  disabled={loadingDelete[one._id]}
                                  onClick={() => {
                                    deleteProduct(one._id);
                                  }}
                                >
                                  {loadingDelete[one._id] ? (
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                  ) : (
                                    <i className="fa-solid fa-trash-can"></i>
                                  )}
                                </button>
                              </Col>
                            </Row>
                          ),
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* ========= Modal ========= */}
        <Modal
          isOpen={modal}
          toggle={handleCloseStd1}
          className="my-modal"
          centered
          size="xl"
        >
          {prodImages && (
            <>
              <h3 className="text-center m-2">Images</h3>
              <Row className="d-flex justify-content-center align-items-center">
                {prodImages.map((one, index) => {
                  return (
                    <Col
                      key={index}
                      className="d-flex justify-content-center m-3"
                    >
                      <img src={one} alt="" style={{ width: "10vw" }}></img>
                    </Col>
                  );
                })}
              </Row>
            </>
          )}
        </Modal>
      </div>
    </React.Fragment>
  );
};

Products.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Products);
