// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AdminResetPassword from "../pages/Reset Password/admin";
import CallCenterResetPassword from "../pages/Reset Password/callCenter";
import AdminVerification from "../pages/Verification/admin";
import CallCenterVerification from "../pages/Verification/callCenter";
import Dashboard from "../pages/Dashboard/index";
import CallCenter from "../pages/CallCenter/index";
import Users from "../pages/Users/index";
import Products from "pages/Products";
import Promos from "pages/Promos";
import Categories from "pages/Categories";
import Admins from "pages/Admins";
import Blogs from "pages/Blogs";
import Partner from "pages/partner";
import Pages404 from "pages/Utility/pages-404";
import Orders from "pages/Orders";
import SubCategories from "pages/Sub Categories";
import Banners from "pages/banners";
import AboutUs from "pages/aboutUs";
import Colors from "pages/colors";
import Sale from "pages/Sale";
import Review from "pages/review";
import Countries from "pages/countries";
import Governorates from "pages/governorates";
import LoyaltyCards from "pages/LoyaltyCards";
import Covers from "pages/Covers";
import Stores from "pages/Stores";


const userRoutes = [
  { path: "/products", component: <Products /> },
  { path: "/orders", component: <Orders /> },
  {
    path: "/dashboard",
    component: sessionStorage.getItem("admin") ? <Dashboard /> : <Pages404 />,
  },
  {
    path: "/admins",
    component: sessionStorage.getItem("admin") ? <Admins /> : <Pages404 />,
  },
  {
    path: "/callCenter",
    component: sessionStorage.getItem("admin") ? <CallCenter /> : <Pages404 />,
  },
  {
    path: "/users",
    component: sessionStorage.getItem("admin") ? <Users /> : <Pages404 />,
  },
  {
    path: "/partner",
    component: sessionStorage.getItem("admin") ? <Partner /> : <Pages404 />,
  },
  {
    path: "/sale",
    component: sessionStorage.getItem("admin") ? <Sale /> : <Pages404 />,
  },
  {
    path: "/review",
    component: sessionStorage.getItem("admin") ? <Review /> : <Pages404 />,
  },
  {
    path: "/banner",
    component: sessionStorage.getItem("admin") ? <Banners /> : <Pages404 />,
  },
  {
    path: "/cover",
    component: sessionStorage.getItem("admin") ? <Covers /> : <Pages404 />,
  },
  {
    path: "/about-us",
    component: sessionStorage.getItem("admin") ? <AboutUs /> : <Pages404 />,
  },
  {
    path: "/promos",
    component: sessionStorage.getItem("admin") ? <Promos /> : <Pages404 />,
  },
  {
    path: "/loyaltyCards",
    component: sessionStorage.getItem("admin") ? <LoyaltyCards /> : <Pages404 />,
  },
  {
    path: "/blogs",
    component: sessionStorage.getItem("admin") ? <Blogs /> : <Pages404 />,
  },
  {
    path: "/categories",
    component: sessionStorage.getItem("admin") ? <Categories /> : <Pages404 />,
  },
  {
    path: "/subCategories",
    component: sessionStorage.getItem("admin") ? (
      <SubCategories />
    ) : (
      <Pages404 />
    ),
  },
  {
    path: "/countries/:id/governorates",
    component: sessionStorage.getItem("admin") ? (
      <Governorates />
    ) : (
      <Pages404 />
    ),
  },
  {
    path: "/blogs",
    component: sessionStorage.getItem("admin") ? <Blogs /> : <Pages404 />,
  },
  {
    path: "/colors",
    component: sessionStorage.getItem("admin") ? <Colors /> : <Pages404 />,
  },
  {
    path: "/stores",
    component: sessionStorage.getItem("admin") ? <Stores /> : <Pages404 />,
  },
  {
    path: "/countries",
    component: sessionStorage.getItem("admin") ? <Countries /> : <Pages404 />,
  },
  { path: "/profile", component: <UserProfile /> },
  {
    path: "/",
    component: sessionStorage.getItem("admin") ? <Dashboard /> : <Products />,
  },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/admin/resetPassword/:token", component: <AdminResetPassword /> },
  {
    path: "/callcenter/resetPassword/:token",
    component: <CallCenterResetPassword />,
  },
  { path: "/admins/verify/:token", component: <AdminVerification /> },
  {
    path: "/callCenter/verify/:token",
    component: <CallCenterVerification />,
  },
];

export { userRoutes, authRoutes };
