/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Form,
  CardHeader,
  Spinner,
} from "reactstrap";
import format from "date-fns/";
import { Link } from "react-router-dom";

import "chartist/dist/scss/chartist.scss";
import AddEditCallCenter from "./addDeleteCover.js";

//i18n
import { withTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import axios from "axios/axios.js";
import { MDBDataTable } from "mdbreact";

const CallCenter = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const exportCSV = () => {
    setLoadingSubmit(true);
    axios({
      url: "qrcode/exportQrCodes", // Replace with your backend URL
      method: "GET",
      responseType: "blob", // Specify the response type as blob
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Codes.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoadingSubmit(false);
      })
      .catch((error) => {
        console.error("Error downloading the Excel file:", error);
      });
  };
  const columns = [
    // {
    // 	label: "ID",
    // 	field: "_id",
    // 	sort: "asc",
    // },
    {
      label: "Image",
      field: "img",
      sort: "asc",
    },
    {
      label: "",
      field: "actions",
    },
  ];
  const callCenterInitial = {
    name: "",
    email: "",
    phone: "",
    password: "",
  };
  const [menu, setMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [callCenters, setCallCenters] = useState([]);
  const [callCenter, setCallCenter] = useState(callCenterInitial);
  const [selectedCallCenter, setSelectedCallCenter] = useState();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    axios
      .get("/cover", {
        headers: {
          Authorization: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        setCallCenters(res.data);
      })
      .catch((e) => {});
  }, [reload]);

  const handleDelete = (id) => {
    let confirm = window.confirm(
      "You are about to delete this image, do you wish to continue?"
    );
    if (confirm) {
      axios
        .delete(`cover/${id}`, {
          headers: {
            Authorization: sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          setCallCenter(callCenterInitial);
          setSelectedCallCenter(null);
          setReload(!reload);
        })
        .catch((e) => {});
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h5 className="page-title">Cover</h5>
              </Col>
            </Row>
          </div>

          <Row className="mb-4">
            <Col>
              <Accordion expanded={menu} style={{ boxShadow: "none" }}>
                <AccordionSummary
                  style={{ backgroundColor: "#E9ECEF" }}
                  onClick={() => {
                    setMenu(!menu);
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h6
                    style={{
                      fontWeight: "bold",
                      color: "#5B626B",
                    }}
                  >
                    {!selectedCallCenter ? "Add" : "Edit"} Cover
                  </h6>
                </AccordionSummary>
                <AccordionDetails>
                  <AddEditCallCenter
                    callCenter={callCenter}
                    selectedCallCenter={selectedCallCenter}
                    setCallCenter={setCallCenter}
                    setSelectedCallCenter={setSelectedCallCenter}
                    setReload={setReload}
                  />
                </AccordionDetails>
              </Accordion>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h6 style={{ fontWeight: "bold" }}>Covers List</h6>
                </CardHeader>
                <CardBody>
                  {/* <div className="col-12">
                    <button
                      className="btn btn-primary"
                      style={{ marginBottom: "3vh" }}
                      type={loadingSubmit ? null : "Export"}
                      onClick={exportCSV}
                    >
                      {loadingSubmit ? (
                        <Spinner size={"sm"} color="secondary" />
                      ) : (
                        <>Export</>
                      )}
                    </button>
                  </div> */}
                  <MDBDataTable
                    hover
                    responsive
                    noBottomColumns
                    data={{
                      columns: columns,
                      rows: callCenters.map((one, index) => {
                        return {
                          ...one,
                          img: (
                            <img
                              src={one.image}
                              alt=""
                              style={{ width: "5vw" }}
                            ></img>
                          ),
                          actions: (
                            <Row
                              style={{
                                paddingLeft: "1vw",
                                paddingRight: "1vw",
                              }}
                            >
                              {/* <Col md={6} xs={6}>
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault()
                                                                        if (
                                                                            selectedCallCenter &&
                                                                            selectedCallCenter._id ===
                                                                                one._id
                                                                        ) {
                                                                            setCallCenter(
                                                                                callCenterInitial
                                                                            )
                                                                            setSelectedCallCenter(
                                                                                null
                                                                            )
                                                                        } else {
                                                                            setCallCenter(
                                                                                {
                                                                                    name: one.name,
                                                                                    email: one.email,
                                                                                    phone: one.phone,

                                                                                    fee: one.fee,
                                                                                }
                                                                            )
                                                                            setSelectedCallCenter(
                                                                                {
                                                                                    _id: one._id,
                                                                                    name: one.name,
                                                                                    email: one.email,
                                                                                    phone: one.phone,

                                                                                    fee: one.fee,
                                                                                }
                                                                            )
                                                                            setMenu(
                                                                                true
                                                                            )
                                                                            window.scrollTo(
                                                                                {
                                                                                    top: 0,
                                                                                    behavior:
                                                                                        "smooth",
                                                                                }
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    {selectedCallCenter &&
                                                                    selectedCallCenter._id ===
                                                                        one._id ? (
                                                                        <i
                                                                            className="ion ion-md-close"
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        ></i>
                                                                    ) : (
                                                                        <i className="fas fa-edit"></i>
                                                                    )}
                                                                </Link>
                                                            </Col> */}
                              <Col md={6} xs={6}></Col>
                              {/* <Col md={3} xs={3}>
                                <Link
                                  to="#"
                                  className="btn btn-danger btn-sm"
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setCallCenter(one)
                                    setSelectedCallCenter(one)
                                    setEdit(true)
                                    setMenu(true)
                                  }}
                                >
                                  <i className="fas fa-pen"></i>
                                </Link>
                              </Col> */}
                              <Col md={3} xs={3}>
                                <Link
                                  to="#"
                                  className="btn btn-danger btn-sm"
                                  style={{
                                    marginLeft: "0.2vw",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleDelete(one._id);
                                  }}
                                >
                                  <i className="fas fa-trash"></i>
                                </Link>
                              </Col>
                            </Row>
                          ),
                        };
                      }),
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CallCenter.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(CallCenter);
