/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
    Form,
    CardHeader,
    Alert,
} from "reactstrap"
import { Link } from "react-router-dom"

import "chartist/dist/scss/chartist.scss"
import AddEditCallCenter from "./AddEditCallCenter.js"

//i18n
import { withTranslation } from "react-i18next"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from "@material-ui/core"
import axios from "axios/axios.js"
import { MDBDataTable } from "mdbreact"

const CallCenter = () => {
    const columns = [
        {
            label: "Name",
            field: "name",
            sort: "asc",
        },
        {
            label: "Email",
            field: "email",
            sort: "asc",
        },
        {
            label: "Phone Number",
            field: "phone",
            sort: "asc",
        },
        {
            label: "Verified",
            field: "verified",
        },
        {
            label: "Actions",
            field: "actions",
        },
    ]
    const callCenterInitial = {
        name: "",
        email: "",
        phone: "",
        verified: false,
        password: "",
    }
    const [menu, setMenu] = useState(false)
    const [reload, setReload] = useState(false)
    const [callCenters, setCallCenters] = useState([])
    const [callCenter, setCallCenter] = useState(callCenterInitial)
    const [selectedCallCenter, setSelectedCallCenter] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const [addErrorMsg, setAddErrorMsg] = useState('')

    useEffect(() => {
        axios
            .get("admins/callCenters", {
                headers: {
                    Authorization: sessionStorage.getItem("token"),
                },
            })
            .then((res) => {
                setCallCenters(
                    res.data.callCenters.map((one) => {
                        return {
                            _id: one._id,
                            name: one.name,
                            email: one.email,
                            phone: one.phone,
                            verified: one.verified,
                        }
                    })
                )
            })
            .catch((e) => {
                setErrorMsg(e.response.data?.message || "Something went wrong")
            })
    }, [reload])

    const handleDelete = (id) => {
        setErrorMsg('')
        setAddErrorMsg('')
        let confirm = window.confirm(
            "You are about delete this call center personnel, do you wish to continue?"
        )
        if (confirm) {
            axios
                .delete(`admins/deleteCallCenter/${id}`, {
                    headers: {
                        Authorization: sessionStorage.getItem("token"),
                    },
                })
                .then((res) => {
                    setCallCenter(callCenterInitial)
                    setSelectedCallCenter(null)
                    setReload(!reload)
                })
                .catch((e) => {
                    setErrorMsg(e.response.data?.message || "Something went wrong")
                })
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box">
                        <Row className="align-items-center">
                            <Col md={8}>
                                <h5 className="page-title">Call Centers</h5>
                            </Col>
                        </Row>
                    </div>
                    <Row className="mb-4">
                        <Col>
                            <Accordion
                                expanded={menu}
                                style={{ boxShadow: "none" }}
                            >
                                <AccordionSummary
                                    style={{ backgroundColor: "#E9ECEF" }}
                                    onClick={() => {
                                        setMenu(!menu)
                                    }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <h6
                                        style={{
                                            fontWeight: "bold",
                                            color: "#5B626B",
                                        }}
                                    >
                                        {!selectedCallCenter ? "Add" : "Edit"}{" "}
                                        CallCenter
                                    </h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <AddEditCallCenter
                                        callCenter={callCenter}
                                        selectedCallCenter={selectedCallCenter}
                                        setCallCenter={setCallCenter}
                                        setSelectedCallCenter={
                                            setSelectedCallCenter
                                        }
                                        setReload={setReload}
                                        setError={setAddErrorMsg}
                                        error={addErrorMsg}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardHeader>
                                    <h6 style={{ fontWeight: "bold" }}>
                                        Call Centers List
                                    </h6>
                                </CardHeader>
                                <CardBody>
                                    { errorMsg && <Alert color="danger">{errorMsg}</Alert> }
                                    <MDBDataTable
                                        hover
                                        responsive
                                        noBottomColumns
                                        data={{
                                            columns: columns,
                                            rows: callCenters.map((one) => {
                                                return {
                                                    ...one,
                                                    verified: one.verified.toString(),
                                                    actions: (
                                                        <Row
                                                            style={{
                                                                paddingLeft:
                                                                    "1vw",
                                                                paddingRight:
                                                                    "1vw",
                                                            }}
                                                        >
                                                            {/* <Col md={6} xs={6}>
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault()
                                                                        if (
                                                                            selectedCallCenter &&
                                                                            selectedCallCenter._id ===
                                                                                one._id
                                                                        ) {
                                                                            setCallCenter(
                                                                                callCenterInitial
                                                                            )
                                                                            setSelectedCallCenter(
                                                                                null
                                                                            )
                                                                        } else {
                                                                            setCallCenter(
                                                                                {
                                                                                    name: one.name,
                                                                                    email: one.email,
                                                                                    phone: one.phone,

                                                                                    fee: one.fee,
                                                                                }
                                                                            )
                                                                            setSelectedCallCenter(
                                                                                {
                                                                                    _id: one._id,
                                                                                    name: one.name,
                                                                                    email: one.email,
                                                                                    phone: one.phone,

                                                                                    fee: one.fee,
                                                                                }
                                                                            )
                                                                            setMenu(
                                                                                true
                                                                            )
                                                                            window.scrollTo(
                                                                                {
                                                                                    top: 0,
                                                                                    behavior:
                                                                                        "smooth",
                                                                                }
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    {selectedCallCenter &&
                                                                    selectedCallCenter._id ===
                                                                        one._id ? (
                                                                        <i
                                                                            className="ion ion-md-close"
                                                                            style={{
                                                                                color: "white",
                                                                            }}
                                                                        ></i>
                                                                    ) : (
                                                                        <i className="fas fa-edit"></i>
                                                                    )}
                                                                </Link>
                                                            </Col> */}
                                                            <Col md={6} xs={6}>
                                                                <Link
                                                                    to="#"
                                                                    className="btn btn-danger btn-sm"
                                                                    style={{
                                                                        marginLeft:
                                                                            "0.2vw",
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault()
                                                                        handleDelete(
                                                                            one._id
                                                                        )
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    ),
                                                }
                                            }),
                                        }}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

CallCenter.propTypes = {
    t: PropTypes.any,
}

export default withTranslation()(CallCenter)
